.container {
	background-color: white;
	color: #6b8ce4;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.10);
	z-index: 100;
}

.container>a, .container>a:visited {
	text-decoration: none;
	color: inherit;
	opacity: 1;
}

.container h2 {
	margin-left: 20px;
	font-size: 1.5em;
}

.rightContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 25px;
}

.rightContainer>a, .rightContainer span {
	opacity: 0.8;
	cursor: pointer;
	color: rgb(89, 126, 230);
	text-decoration: none;
	margin-right: 20px;
}

.rightContainer>a:last-child, .rightContainer>span:last-child {
	margin-right: 0px;
}

.rightContainer>a:hover, .rightContainer>span:hover {
	color: #1643be;
}