@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideIn {
	from {
		transform: translateY(-200%);
	}
	to {
		transform: translateY(0%);
	}
}

.container {
	animation: fadein 0.1s;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 15px;
}

.modalBox {
	animation: slideIn 0.2s;
	background-color: white;
	max-width: 500px;
	margin: 100px auto;
	border: 4px solid #6b8ce4;
	padding: 20px;
}