.bodyContainer {
	background-color: white;
	padding: 15px;
	padding-bottom: 0;
	padding-top: 0;
	flex: 1;
}

.cardContainer {
	margin: 15px auto;
	max-width: 1000px;
	padding: 15px;
	background-color: white;
	position: relative;
	box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.15);
	border-radius: 2px;
}

.cardTitle {
	color: #6b8ce4;
}

.padContainer {
	max-width: 1000px;
	margin: auto;
	margin-bottom: 50px;
	padding: 0px;
	background-color: white;
	position: relative;
}

.link {
	cursor: pointer;
	color: #515151;
	text-decoration: underline;
}

.plainLink {
	cursor: pointer;
	color: #515151;
	text-decoration: none;
}
